import {Topology} from '@wix/wixstores-client-core/dist/es/src/constants';
import {SiteStore} from '@wix/wixstores-client-storefront-sdk/dist/es/src/viewer-script/site-store/SiteStore';
import {GRAPHQL_SOURCE, graphqlOperation} from '../constants';
import GetBackInStockSettingsDocument from '../graphql/getBackInStockSettings.graphql';

export type GetBackInStockSettingsQuery = {
  __typename?: 'WixStoresQueries';
  backInStock: {
    __typename?: 'BackInStockQueries';
    settings: {__typename?: 'BackInStockSettings'; isCollectingRequests: boolean};
  };
};

export async function getBackInStockSettings(siteStore: SiteStore): Promise<GetBackInStockSettingsQuery> {
  const url = siteStore.resolveAbsoluteUrl(`/${Topology.NODE_GRAPHQL_URL}`);
  const bodyData = {
    query: GetBackInStockSettingsDocument,
    operationName: graphqlOperation.GetBackInStockSettings,
    source: GRAPHQL_SOURCE,
  };

  /**
   * todo(titk@wix.com): replace with
   * siteStore.tryGetGqlAndFallbackToPost(url, bodyData)
   * when issue with get on node server is resolved
   */
  const result = await siteStore.httpClient.post(url, bodyData);

  return result.data;
}
