import {getBackInStockSettings} from './getBackInStockSettings';
import {SiteStore} from '@wix/wixstores-client-storefront-sdk/dist/es/src/viewer-script/site-store/SiteStore';
import {IPropsInjectedByViewerScript} from '../types/app-types';
import {APP_DEFINITION_ID as STORES_APP_DEF_ID} from '@wix/wixstores-client-core/dist/es/src/constants';
import {SPECS} from '../specs';

export enum BackInStockCollectingStatus {
  Unknown,
  Pending,
  Yes,
  No,
}

export interface OpenBackInStockModalParams {
  variantId: string;
}

export interface BackInStockServiceProps {
  isBackInStockEnabled: boolean;
  isBackInStockLoading: boolean;
  handleNotifyWhenBackInStock: (params: OpenBackInStockModalParams) => void;
}

export interface BackInStockServiceDependencies {
  siteStore: SiteStore;
  setProps: (props: Partial<IPropsInjectedByViewerScript>) => void;
  resolveProductId: () => Promise<string>;
  compId: string;
}

interface BackInStockRequestModalOpenParams {
  variantId: string;
  productId: string;
  appId: string;
}

export class BackInStockService {
  constructor(private readonly dependencies: BackInStockServiceDependencies) {}

  public async initialize() {
    if (!this.isExperimentEnabled) {
      return;
    }

    await this.fetchIsCollectingRequests();
  }

  public get props(): BackInStockServiceProps {
    return {
      isBackInStockEnabled: this.isExperimentEnabled && this.collectingStatus === BackInStockCollectingStatus.Yes,
      isBackInStockLoading: this.collectingStatus === BackInStockCollectingStatus.Pending,
      handleNotifyWhenBackInStock: /* istanbul ignore next todo(titk@wix.com): remove when modal is implemented */ (
        params
      ) => {
        //eslint-disable-next-line @typescript-eslint/no-floating-promises
        this.openBackInStockModal(params);
      },
    };
  }

  private get isExperimentEnabled() {
    return this.dependencies.siteStore.experiments.enabled(SPECS.BACK_IN_STOCK_PRODUCT_PAGE_BUTTON);
  }

  private updateProps() {
    this.dependencies.setProps(this.props);
  }

  private collectingStatus = BackInStockCollectingStatus.Unknown;

  /* istanbul ignore next todo(aleksandrg@wix.com): remove when modal is implemented */
  private getBackInStockModalUrl(openParams: BackInStockRequestModalOpenParams): string {
    const url = new URL(`https://ecom.wix.com/back-in-stock-request-modal`);
    for (const [key, value] of Object.entries(openParams)) {
      url.searchParams.append(key, value);
    }
    return url.toString();
  }

  /* istanbul ignore next todo(titk@wix.com): remove when modal is implemented */
  private async openBackInStockModal({variantId}: OpenBackInStockModalParams) {
    if (!this.isExperimentEnabled) {
      return;
    }

    const productId = await this.dependencies.resolveProductId();

    const modalOpenParam: BackInStockRequestModalOpenParams = {
      variantId,
      productId,
      appId: STORES_APP_DEF_ID,
    };

    // todo(aleksandrg@wix.com): this is a POC implementation. Do in correct platformized way. See EE-30945
    return this.dependencies.siteStore.windowApis.openModal(
      this.getBackInStockModalUrl(modalOpenParam),
      {
        width: 603,
        height: 348,
        theme: 'BARE',
      },
      this.dependencies.compId
    );
  }

  private async fetchIsCollectingRequests() {
    try {
      this.collectingStatus = BackInStockCollectingStatus.Pending;

      const result = await getBackInStockSettings(this.dependencies.siteStore);

      this.collectingStatus = result.backInStock.settings.isCollectingRequests
        ? BackInStockCollectingStatus.Yes
        : BackInStockCollectingStatus.No;
    } catch (e) {
      /* istanbul ignore next */
      this.collectingStatus = BackInStockCollectingStatus.Unknown;
    } finally {
      this.updateProps();
    }
  }
}
